@import "_fields.css";
@import "_helpers.css";
@import "_badges.css";
@import "_skeleton.css";
@import "_code-input.css";
@import "_mobile-card.css";


.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  user-select: none;
}

html, html * {
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;

    &-track {
      background-color: transparent;
    }

    &-thumb {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

}

html a {
  color: var(--purple6);
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.3s ease;
}

html {
  height: 100%;

  --black9: #121417;
  --black8: #1B1F23;
  --black7: #262A30;
  --black6: #454C56;
  --black5: #555D67;
  --black4: #777F89;
  --black3: #A2AAB4;
  --black2: #DFE5E9;
  --black1: #E8EDF0;
  --black0: #F5F7F8;
  --white: white;
  --black: #121417;

  --button_color: #5129AE;
  --link_color: #6132cf;

  --purple9: #43248E;
  --purple8: #5129AE;
  --purple7: #6132cf;
  --purple6: #6a3ce2;
  --purple5: #8164ee;
  --purple4: #a191f4;
  --purple3: #c0b9f9;
  --purple2: #dbd8fc;
  --purple1: #eceafd;
  --purple0: #f4f4fe;

  --red9: #381E1D;
  --red8: #60211F;
  --red7: #A53E3E;
  --red6: #D24242;
  --red5: #E85757;
  --red4: #f87171;
  --red3: #fca5a5;
  --red2: #fecaca;
  --red1: #fee2e2;
  --red0: #fef2f2;

  --orange9: #35241E;
  --orange8: #753621;
  --orange7: #BC542A;
  --orange6: #E16727;
  --orange5: #F17C2A;
  --orange4: #F69546;
  --orange3: #FCB871;
  --orange2: #fed7aa;
  --orange1: #F9ECDB;
  --orange0: #fff7ed;

  --green9: #143120;
  --green8: #166534;
  --green7: #15803d;
  --green6: #239F51;
  --green5: #35C068;
  --green4: #66DB91;
  --green3: #8CEDAF;
  --green2: #bbf7d0;
  --green1: #E3F5E9;
  --green0: #f0fdf4;

  --pink9: #701338;
  --pink8: #9D174D;
  --pink7: #BE185D;
  --pink6: #DB2777;
  --pink5: #EC4899;
  --pink4: #F472B6;
  --pink3: #F9A8D4;
  --pink2: #FBCFE8;
  --pink1: #FCE7F3;
  --pink0: #FDF2F8;

  --blue9: #1E3A8A;
  --blue8: #1E40AF;
  --blue7: #1D4ED8;
  --blue6: #2563EB;
  --blue5: #3B82F6;
  --blue4: #60A5FA;
  --blue3: #93C5FD;
  --blue2: #BFDBFE;
  --blue1: #DBEAFE;
  --blue0: #EFF6FF;

  --cyan9: #164E63;
  --cyan8: #155E75;
  --cyan7: #0E7490;
  --cyan6: #0891B2;
  --cyan5: #06B6D4;
  --cyan4: #22D3EE;
  --cyan3: #67E8F9;
  --cyan2: #A5F3FC;
  --cyan1: #D4F6F9;
  --cyan0: #EEF7F8;

  --border-radius: .5rem;
  --modal-border-radius: .5rem;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--black2);

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;

    &-track {
      background-color: transparent;
    }

    &-thumb {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}

html.custom {
  height: 100%;

  --black9: var(--primary-font-color);
  //--black8: #1B1F23;
  //--black7: #262A30;
  --black6: #454C56;
  --black5: var(--secondary-font-color);
  --black4: #777F89;
  --black3: #A2AAB4;
  --black2: #DFE5E9;
  --black1: #E8EDF0;
  --black0: #F5F7F8;
  --white: var(--main-background-color);
  --black: #121417;

  body {
    background: var(--secondary-background-color);
  }

  --button_color: #5129AE;
  --link_color: #6132cf;

  --purple9: #43248E;
  --purple8: #5129AE;
  --purple7: #6132cf;
  --purple6: var(--brand-color);
  --purple5: #8164ee;
  --purple4: #a191f4;
  --purple3: #c0b9f9;
  --purple2: #dbd8fc;
  --purple1: #eceafd;
  --purple0: var(--brand-hover-background);

  --red9: #381E1D;
  --red8: #60211F;
  --red7: #A53E3E;
  --red6: #D24242;
  --red5: #E85757;
  --red4: #f87171;
  --red3: #fca5a5;
  --red2: #fecaca;
  --red1: #fee2e2;
  --red0: #fef2f2;

  --orange9: #35241E;
  --orange8: #753621;
  --orange7: #BC542A;
  --orange6: #E16727;
  --orange5: #F17C2A;
  --orange4: #F69546;
  --orange3: #FCB871;
  --orange2: #fed7aa;
  --orange1: #F9ECDB;
  --orange0: #fff7ed;

  --green9: #143120;
  --green8: #166534;
  --green7: #15803d;
  --green6: #239F51;
  --green5: #35C068;
  --green4: #66DB91;
  --green3: #8CEDAF;
  --green2: #bbf7d0;
  --green1: #E3F5E9;
  --green0: #f0fdf4;

  --pink9: #701338;
  --pink8: #9D174D;
  --pink7: #BE185D;
  --pink6: #DB2777;
  --pink5: #EC4899;
  --pink4: #F472B6;
  --pink3: #F9A8D4;
  --pink2: #FBCFE8;
  --pink1: #FCE7F3;
  --pink0: #FDF2F8;

  --blue9: #1E3A8A;
  --blue8: #1E40AF;
  --blue7: #1D4ED8;
  --blue6: #2563EB;
  --blue5: #3B82F6;
  --blue4: #60A5FA;
  --blue3: #93C5FD;
  --blue2: #BFDBFE;
  --blue1: #DBEAFE;
  --blue0: #EFF6FF;

  --cyan9: #164E63;
  --cyan8: #155E75;
  --cyan7: #0E7490;
  --cyan6: #0891B2;
  --cyan5: #06B6D4;
  --cyan4: #22D3EE;
  --cyan3: #67E8F9;
  --cyan2: #A5F3FC;
  --cyan1: #D4F6F9;
  --cyan0: #EEF7F8;

  --cyan0: #edf7f80d;
  --red0: #d242420d;
  --green0: #1431200d;
  --blue0: #2563eb0d;
  --orange0: #fff6eb0d;

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;

    &-track {
      background-color: transparent;
    }

    &-thumb {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .sellix-table table tbody tr:hover, .navigation-item.active {
    border-color: var(--brand-color) !important;
    background: var(--brand-hover-background) !important;
  }

  .sellix-table table tbody tr td {
    border-top-color: var(--border-color) !important;
    border-top-style: var(--border-style) !important;
  }

  .dashboard-info-card-info {
    color: var(--brand-color) !important;
  }

  .dashboard-info-card-info {
    color: var(--brand-color) !important;
  }

  .sellix-top-up-modal .sellix-modal-body .sellix-input + div {
    background-color: var(--secondary-background-color) !important;
    border-radius: 0 var(--input-radius) var(--input-radius) 0 !important;
  }

  .sellix-top-up-modal .sellix-modal-body .sellix-input {
    border-radius: var(--input-radius) 0 0 var(--input-radius) !important;
  }

  div.daterangepicker .ranges ul li.active {
    background: var(--brand-hover-background);
    color: var(--brand-color);
  }

  div.daterangepicker.show-ranges.ltr .drp-calendar.left {
    border-left: var(--border-width) solid var(--border-color) !important;
    border-right: var(--border-width) solid var(--border-color) !important;
  }
  div.daterangepicker .drp-buttons {
    border: none;
    border-top: var(--border-width) solid var(--border-color) !important;
  }

  .invoice-list-id, .breadcrumbs-container .breadcrumbs .link, .breadcrumbs-container .breadcrumbs svg {
    color: var(--secondary-font-color);
  }

  .header-info > div:last-child span {
    color: var(--secondary-font-color);
  }

  .affiliate-top-link-input {
    background: var(--brand-hover-background);
    color: var(--brand-color);
  }

  .invoice-details-products-item:hover {
    background: var(--brand-hover-background);
  }

  input.sellix-input, select.select, .sellix-date-picker, .react-code-input input {
    background: var(--input-background) !important;
    color: var(--input-font-color);
    border-radius: var(--input-radius) !important;
    border-color: var(--input-border-color) !important;
    border-width: var(--input-border-width) !important;
    border-style: var(--input-border-style) !important;
    font-weight: var(--input-font-weight);
  }

  .react-code-input input {
    border-color: var(--input-border-color) !important;
    background: var(--input-background) !important;
  }

  .sellix-note {
    color: var(--secondary-font-color);
  }

  .empty-text {
    color: var(--secondary-font-color);
  }

  .sellix-date-picker > div, .sellix-date-picker > svg {
    color: var(--input-font-color);
  }
  .select-container svg path {
    stroke: var(--input-font-color);
  }
  .screen-search svg path {
    stroke: var(--input-font-color);
  }

  .header-info > div:last-child div svg path {
    stroke: var(--secondary-font-color);
  }

  .table-mobile-card-header {
    border-color: var(--border-color);
  }

  .table-mobile-card-header-icon {
    color: var(--brand-color);
    border: var(--border-width) var(--border-style) var(--border-color);
    background: var(--brand-hover-background);
  }

  @media (max-width: 768px) {
    .navigation {
      background: var(--secondary-background-color);
      padding-top: 0;
    }
  }

  .table-mobile-card-header-main div {
    color: var(--secondary-font-color);
  }

  .sellix-pagination-container button > svg {
    color: var(--secondary-font-color) !important;
  }

  .sellix-table-clear-text {
    color: var(--secondary-font-color);
  }

  .info-table {
    border-color: var(--border-color);
    border-width: var(--border-width);
  }

  .table-mobile-card-body-list > div {
    color: var(--secondary-font-color);
  }

  .dashboard-info-table > span, .info-table-header, .invoice-details-info, .sellix-table-pagination, .invoice-details-products-header {
    border-color: var(--border-color);
  }

  .sellix-table-pagination {
    border-top-color: var(--border-color) !important;
    border-top-style: var(--border-style) !important;
  }

  .info-table.table .sellix-table-container .sellix-table table tbody tr td {
    border-top-color: var(--border-color) !important;
    border-top-style: var(--border-style) !important;
  }

  input::placeholder {
    color: var(--secondary-background-color) !important;
  }

  button.sellix-button {
    background-color: var(--brand-color) !important;
    color: var(--main-background-color);

    &.sellix-button-secondary {
      background-color: var(--button-secondary-background) !important;
      color: var(--button-secondary-font-color) !important;
      border-color: var(--button-secondary-border-color) !important;
      border-width: var(--button-secondary-border-width) !important;
      border-style: var(--button-secondary-border-style) !important;
      font-weight: var(--button-secondary-font-weight) !important;
      border-radius: var(--button-secondary-radius) !important;

      box-shadow: none;

      &:hover {
        background-color: var(--button-secondary-background) !important;
      }
    }
  }


  .sellix-table-pagination button.sellix-button.active {
    border-color: var(--border-color) !important;
    border-width: var(--border-width) !important;
  }


  .license-screen table tbody tr a {
    color: var(--brand-color) !important;
  }

  a {
    color: var(--brand-color);
  }

  @media (max-width: 768px) {
    .navigation a {
      color: var(--black5);

      &.active {
        color: var(--primary-font-color);
      }
    }
  }

  .skeleton {
    background: var(--brand-hover-background);
    border-radius: var(--border-radius);
  }

  .sellix-table table thead th,
  .dashboard-info-product span,
  .sellix-table-pagination .page-size-container > span,
  .dashboard-info-card span,
  .product-table-type-date {
    color: var(--secondary-font-color);
  }

  .product-table-type-icon, .invoice-list-icon {
    color: var(--brand-color);
    border-color: var(--brand-color) !important;
    background-color: var(--brand-hover-background) !important;
    border-radius: .5rem;

    img {
      border-radius: .5rem;
    }
  }

  .sellix-spin {
    div {
      background-color: var(--brand-color);
    }
  }


  .header-profile-dropdown, .navigation-dropdown {
    background-color: var(--main-background-color);
    color: var(--primary-font-color);
    border-radius: var(--border-radius);
    border: var(--border-width) var(--border-style) var(--border-color);
    //overflow: hidden;

    .header-menu {
      background-color: var(--main-background-color);
      border-color: var(--border-color);

      .header-menu-header {
        border-color: var(--border-color);
        color: var(--primary-font-color);
      }

      .header-menu-item {
        color: var(--primary-font-color);

        svg path {
          stroke: var(--brand-color);
        }
      }

      & > .navigation-item {
        color: var(--primary-font-color);

        svg path {
          stroke: var(--brand-color);
        }
      }

      & + .navigation-item {
        color: var(--primary-font-color);

        svg path {
          stroke: var(--brand-color);
        }
      }
    }

    .navigation-item {
      &:hover {
        color: var(--primary-font-color);
        background-color: transparent;

        svg path {
          stroke: var(--primary-font-color);
        }
      }
    }
  }


  .gift-card {
    background: var(--main-background-color);
    border-radius: var(--border-radius);

    .gift-card-input {
      background: var(--input-background) !important;
      color: var(--input-font-color);
      border-radius: var(--input-radius) !important;
      border-color: var(--input-border-color) !important;
      border-width: var(--input-border-width) !important;
      border-style: var(--input-border-style) !important;
      font-weight: var(--input-font-weight);

      svg {
        color: var(--input-font-color);
      }
    }

    .gift-card-subtext {
      color: var(--secondary-font-color) !important;
    }

    .gift-card-icon {
      background: var(--brand-hover-background);
      border: var(--border-width) var(--border-style) var(--border-color);
      color: var(--brand-color);
    }
  }


  .gift-card {
    background: var(--main-background-color);
    border-radius: var(--border-radius);
    border: var(--border-width) var(--border-style) var(--border-color);

    .gift-card-input {
      background: var(--input-background) !important;
      color: var(--input-font-color);
      border-radius: var(--input-radius) !important;
      border-color: var(--input-border-color) !important;
      border-width: var(--input-border-width) !important;
      border-style: var(--input-border-style) !important;
      font-weight: var(--input-font-weight);

      svg {
        color: var(--input-font-color);
      }
    }

    .gift-card-subtext {
      color: var(--secondary-font-color) !important;
    }

    .gift-card-icon {
      background: var(--brand-hover-background);
      border: var(--border-width) var(--border-style) var(--border-color);
      color: var(--brand-color);
    }
  }


  .sellix-checkbox label:before {
    border: var(--input-border-width) var(--input-border-style) var(--input-border-color) !important;
  }

  .sellix-checkbox input:checked ~ label::before {
    background-color: var(--brand-color) !important;
    border: var(--input-border-width) var(--input-border-style) var(--brand-color) !important;
  }

  .sellix-checkbox input:checked ~ label::after {
    border-color: var(--brand-color) !important;
  }

  .sellix-label {
    font-family: inherit;
    color: var(--secondary-font-color);
  }

  .sellix-input, .sellix-select {
    border-radius: var(--input-radius) !important;
    border-width: var(--input-border-width) !important;
    border-style: var(--input-border-style) !important;
    border-color: var(--input-border-color) !important;
    background-color: var(--input-background) !important;
    color: var(--input-font-color);
    font-weight: var(--input-font-weight);

    &.is-invalid {
      border-width: var(--input-border-width);
    }

    &:hover, &:focus {
      border-color: var(--brand-color) !important;
    }

    &::placeholder {
      color: var(--primary-font-color) !important;
      opacity: 0.5;
      font-weight: 300;
    }
  }

  .color-switch-slider {
    background-color: var(--brand-color);

    &:before {
      background-color: var(--secondary-font-color);
    }
  }

  .sellix-switcher-container {
    .peer:checked ~ .peer-checked\:bg-purple-600 {
      background-color: var(--brand-color);
    }

    .sellix-switcher-label {
      .text-sm {
        color: var(--primary-font-color);
      }

      .text-xs {
        color: var(--secondary-font-color);
      }
    }
  }

  .item-second, .item-first {

    path, rect {
      fill: var(--secondary-font-color);
    }

    .item-first-back {
      fill: var(--secondary-background-hover);
    }
  }




  button.sellix-button, .affiliate-screen-button {
    border-radius: var(--button-radius) !important;
    background: var(--button-background) !important;
    color: var(--button-font-color) !important;
    border-color: var(--button-border-color) !important;
    border-width: var(--button-border-width) !important;
    border-style: var(--button-border-style);
    font-weight: var(--button-font-weight) !important;

    &:hover {
      background: var(--button-background-hover) !important;
    }

    svg path {
      stroke: currentColor;
    }

  }

  .invoice-details-products-header > div, .invoice-details-info > div:first-child span, .info-table-body > div:not(.sellix-table-container) {
    color: var(--secondary-font-color);
  }

  .sellix-modal {
    background-color: var(--main-background-color);
    border-radius: var(--modal-border-radius);
  }

  .info-table-body > div:not(.sellix-table-container) {
    border-top-color: var(--border-color);
    border-top-style: var(--border-style);
  }

  .sellix-wallet-button, .sellix-wallet-evm, .wallet-adapter-button {
    background: var(--secondary-background-color) !important;
    color: var(--primary-font-color) !important;
    border-color: var(--border-color) !important;
    border-width: var(--border-width) !important;
    border-radius: var(--border-radius) !important;

    & > div {
      & svg.bg-white {
        //background-color: var(--main-background-color) !important;
        //border-color: var(--main-background-color) !important;
      }
    }

    &-connected {
      color: var(--secondary-font-color);
    }

    &.active {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }
  }

}


html.dark {
  --black0: #121417;
  --black1: #262A30;
  --black2: #262A30;
  --black3: #A2AAB4;
  --black4: #777F89;
  --black5: #555d67;
  --black6: #454C56;
  --black7: #A2AAB4;
  --black9: white;
  --white: #1B1F23;
  --black: white;

  --purple0: #43248e57;
  --purple1: #43248e57;

  --red0: #381E1D;

  --orange0: #35241E;

  --green0: #14312075;

  --pink0: #70133875;

  --blue0: #1E3A8A75;

  --cyan0: #164E6375;
}


body {
  background: var(--black0);
}

.cursor-pointer {
  cursor: pointer;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  border-collapse: collapse;
}

* {
  box-sizing: border-box;
}

a {
  color: var(--purple6);
  text-decoration: none;
  background-color: #0000;

  &:hover {
    text-decoration: none;
  }
}



svg.loader-circular {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 2.25rem;
  height: 2.25rem;
}

circle.loader-path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@-webkit-keyframes color {
  100%, 0% {
    stroke: grey;
  }
  40% {
    stroke: grey;
  }
  66% {
    stroke: grey;
  }
  80%, 90% {
    stroke: grey;
  }
}

@keyframes color {
  100%, 0% {
    stroke: grey;
  }
  40% {
    stroke: grey;
  }
  66% {
    stroke: grey;
  }
  80%, 90% {
    stroke: grey;
  }
}

.dark {
  .info-table {
    background: var(--white);
    border-color: transparent;

    .info-table-header {
      background: var(--black1);
    }
    .info-table-body > div:not(.sellix-table-container) {
      border-color: var(--border-color);
    }

    &.table {
      .info-table-header {
        background: var(--black1);
        border-bottom-color: var(--black0);

        .screen-search input {
          background: var(--black0);
        }
      }
      .info-table-body {
        background-color: var(--black1);
        border-radius: 0 0 var(--border-radius) var(--border-radius);
      }
    }
  }
}



.info-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--white);
  border-radius: var(--border-radius);
  border: var(--border-width) var(--border-style) var(--border-color);
  margin-bottom: 1rem;
  margin-top: 0;

  &.table {
    .sellix-table-container {
      padding: 0;
      box-shadow: none;

      .sellix-table {
        padding: 0;
        box-shadow: none;

        table {
          thead th {
            padding: .5rem 0.75rem;
          }
          tbody tr td {
            border-top-color: var(--border-color) !important;
            border-top-style: var(--border-style) !important;
          }
        }

        &-container {
          padding: 0;
        }
      }
    }
  }

  &.with-shadow {
    box-shadow: 0 1px 3px rgb(7 14 35 / 6%), 0 2px 8px -1px rgb(7 14 35 / 5%);
    border: none;
  }

  &.collapsed {
    .info-table-header {
      border-bottom: none;
      border-radius: var(--border-radius);
    }
  }

  &-header {
    padding: 1.25rem;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--black9);
    cursor: pointer;
    border-radius: var(--border-radius) var(--border-radius) 0 0;

    display: flex;
    justify-content: space-between;
    border-bottom: var(--border-width) var(--border-style) var(--border-color);
  }

  &-body {
    & > div:not(.sellix-table-container) {
      display: flex;
      width: 100%;
      border-top: var(--border-width) var(--border-style) var(--border-color);
      padding: 1rem 1.25rem;
      justify-content: space-between;
      color: var(--black4);
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      border-radius: 0 0 var(--border-radius) var(--border-radius);

      &:first-child {
        border-top: none;
      }

      span:first-child {
        width: 30%;
      }

      span:last-child {
        width: 70%;
        text-align: right;
        color: var(--black9);
        word-break: break-word;
      }
    }
  }
}

@media (max-width: 768px) {

  .info-table {

    &.table {
      .sellix-table-container {

        .sellix-table {

          table {
            thead th {
              padding: .5rem 0.75rem;
            }
            tbody tr td {
            }
          }

          &-container {

          }
        }
      }
    }

    &.with-shadow {

    }

    &.collapsed {
      .info-table-header {

      }
    }

    &-header {
      padding: 1rem;
    }

    &-body {
      & > div:not(.sellix-table-container) {
        padding: .75rem 1rem;

        &:first-child {

        }

        span:first-child {

        }

        span:last-child {

        }
      }
    }
  }
}


.screen {

  &-title {
    margin-top: .5rem;
    margin-bottom: 0;
    font-size: 1.5rem !important;
    white-space: nowrap;
    align-items: center;
    display: flex;
    margin-right: 1.5rem;
    width: auto;
    font-style: normal;
    font-weight: 600;
    line-height: 29px;
    color: var(--black9) !important;

    & + div {
      width: auto;
      display: flex;
      justify-content: flex-end;
    }

    @media (max-width: 768px) {
      font-weight: 500;
      font-size: 1.5rem !important;
    }

    &-container {
      display: flex;

      @media (max-width: 768px) {
        flex-wrap: wrap;
      }
    }
  }

  &-note {
    margin: 1rem 0;
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: var(--black9);

    @media (max-width: 768px) {
      margin: .5rem 0;
    }
  }

  &-header {
    padding: 1.5rem 0;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    position: relative;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem 0;
    }
  }

  &-filters {
    display: flex;
    justify-content: flex-end;

    &:empty {
      display: none;
    }

    @media (max-width: 768px) {
      .screen-search {
        margin-bottom: .75rem;
      }
    }

    &-button {
      margin-left: 1rem;

      @media (max-width: 768px) {
        margin-left: 0;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      margin-top: .25rem;
      display: flex;
      flex-direction: column;
    }

    .sellix-date-picker {
      @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
      }
    }
  }

  &-select {
    max-width: 30%;

    &.long {
      max-width: 50%;
    }
  }

}

@media (max-width: 768px) {
  .screen {

    &-title {
      font-weight: 500;
      font-size: 1.5rem !important;

      & + div {

      }

      &-container {
        flex-wrap: wrap;
      }
    }

    &-note {
      margin: .5rem 0;
    }

    &-header {
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem 0;
    }

    &-filters {
      width: 100%;
      margin-top: 1rem;
      margin-bottom: .25rem;
      display: flex;
      flex-direction: column;

      &:empty {
        display: none;
      }

      & > .sellix-select, .sellix-input {

      }

      &-button {
        margin-left: 0;
      }

      .sellix-date-picker {
        width: 100%;
        justify-content: center;
      }
    }

    &-select {

      &.long {

      }
    }

  }


}


.sellix-table table thead tr {
  th {
    text-align: left;
  }
}



.grecaptcha-badge {
  visibility: hidden;
}


.product-table-type {
  display: flex;

  &-icon {
    width: 2.25rem;
    min-width: 2.25rem;
    height: 2.25rem;
    border-radius: var(--border-radius);
    object-fit: cover;
    margin-right: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black3);
    box-shadow: 0 1px 2px 0 #1214170F;

    background: transparent;
    border: var(--border-width) var(--border-style) var(--border-color);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      border-radius: var(--border-radius);
    }
  }

  &-date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--black4);
    margin-top: .25rem;
  }

  &-title {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    font-size: 14px;
  }
}


.gap-1 {
  gap: .25rem;
}
.gap-2 {
  gap: .5rem;
}
.gap-3 {
  gap: .75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-6 {
  gap: 1.5rem;
}
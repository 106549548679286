.sellix-pagination-container {
  display: flex;
  list-style-type: none;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1.25rem;
  }

  button.sellix-pagination-item {
    color: var(--black5);
    background-color: var(--white);
    border: var(--border-width) var(--border-style) var(--border-color);
    box-shadow: 0 1px 2px rgb(18 20 23 / 6%);
    font-weight: 500;
    cursor: pointer;
    width: 2rem !important;
    padding: 0;
    height: 2rem !important;
    font-size: 0.8rem;

    @media (max-width: 768px) {
      font-size: 0.75rem;
      width: 1.85rem !important;
      height: 1.85rem !important;
      padding: 0 !important;
    }

    svg path {
      stroke: var(--black5);
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background: var(--black0);
      cursor: pointer;
    }

    &.selected {
      border: 2px solid var(--purple6);
    }

    .arrow {
      &::before {
        position: relative;
        content: '';
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
.initial-screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999999999;
  position: fixed;
  background: var(--white);

  svg {
    width: 4rem;
    height: 4rem;

    path {
      fill: var(--purple6);
    }
  }
}

.auth {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;

  @media (max-width: 768px) {
    width: calc(100% - 2rem);
  }

  &-screen {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--black0);

    .sign-button {
      background: var(--button_color);
      color: var(--black0);

      &:hover {
        background: var(--purple6);
      }
    }

    #email {
      background: var(--black0);
    }
  }

  &-captcha {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    &:empty {
      display: none;
    }

    #customer-auth-recaptcha-v2 {
      &:empty {
        display: none;
      }
    }
  }

  &-block {
    margin: 0 auto;
    overflow: auto;
    padding: 2rem;
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: 0 1px 3px rgba(7, 14, 35, 0.06), 0 2px 8px -1px rgba(7, 14, 35, 0.05);

    &-header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: var(--black9);
      }

      &-subtitle {
        margin-top: .5rem;
        margin-bottom: 2rem;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: var(--black5);
      }

    }

    &-form {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      flex-direction: column;

      .sellix-product-form-field {
        padding-bottom: 1rem;
      }
    }

    &-email {
      display: flex;
      flex-direction: row;
    }


  }

}




input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]{
  -moz-appearance: textfield;
}

.react-code-input {
  width: 100%;

  input {
    border: var(--border-width) var(--border-style) var(--border-color) !important;
    box-shadow: none !important;
    width: calc(100%/8 - .5rem) !important;
    border-radius: .25rem !important;
    margin: 0 .25rem !important;

    &:first-child {
      margin-left: 0 !important;
      width: calc(100%/8 - .25rem) !important;
    }

    &:last-child {
      margin-right: 0 !important;
      width: calc(100%/8 - .25rem) !important;
    }

    &:focus {
      outline: none;
      //border: var(--border-width) solid var(--black2)  !important;
    }
  }
}


@media (max-width: 768px) {
  .sellix-customer-auth {
    width: 100% !important;
    margin: 0 1rem;

    &-block {
      width: 100%;
      padding: 1.5rem 1rem;

      &-form {
        width: 100%;

        button {
          width: 100%;
          margin-top: 0 !important;
          margin-left: 0 !important;
        }
      }

      &-email {
        flex-wrap: wrap;
      }
    }

  }

  .react-code-input input {
    width: calc(100%/8 - 0.25rem) !important;
    border-radius: 0.25rem !important;
    margin-right: 0.125rem !important;
    margin-left: 0.125rem !important;
    font-size: 1.25rem !important;
    font-family: inherit !important;
    padding: 0 !important;
    text-align: center;
    font-weight: 500;
  }
}

.scroll-hidden {
  overflow: hidden;
}

@import "../../assets/styles/index.css";

.sellix-modal {
  width: 100%;
  max-width: 640px;
  padding: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--white);
  border-radius: var(--modal-border-radius);
  position: absolute;
  max-height: 90vh;
  overflow: auto;
  box-shadow: 0 1px 3px rgba(7, 14, 35, 0.06), 0 2px 8px rgba(7, 14, 35, 0.05);
  outline: none;

  &:hover {
    outline: none;
  }

  &.visible {
    overflow: visible;
  }

  @media (max-width: 768px) {
    width: calc(100% - 2rem);
    transform: translate(-50%, 0%);
    top: 1rem;
  }

  &.confirm {
    max-width: 400px;
    overflow: hidden;
  }

  &-header {
    padding: 1.25rem 1.5rem;
    border-bottom: var(--border-width) var(--border-style) var(--border-color);
    color: var(--black9);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: 768px) {
      padding: 1rem 1.25rem;
    }
  }

  &-close {
    position: absolute;
    top: 1.25rem;
    right: 1.5rem;
    cursor: pointer;

    svg {
      fill: grey;
    }
  }

  &-body {
    padding: 1.25rem 1.5rem;
    min-height: 5rem;
    color: var(--black4);
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;

    @media (max-width: 768px) {
      padding: 1rem 1.25rem;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-top: var(--border-width) var(--border-style) var(--border-color);

    @media (max-width: 768px) {
      padding: 1rem 1.25rem;
    }
  }

  .sellix-text {
    color: var(--black6);

    h1 {
      font-size: 1.6rem;
      line-height: 3rem;
    }
    h2 {
      font-size: 1.4rem;
      line-height: 2.5rem;
    }
    h3 {
      font-size: 1.3rem;
      line-height: 2.4rem;
    }
    h4 {
      font-size: 1.1rem;
      line-height: 2rem;
    }
    h6 {
      font-weight: 500;
    }

    p {
      font-size: .9rem;
      margin: 0;
      line-height: 1.2rem;
      margin-bottom: .6rem;
      color: var(--black6)
    }

    input[type=checkbox] {
      width: 30px;
      height: 20px;
      cursor: pointer;
      font-size: 21px;
      visibility: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem -2rem;
      transform: scale(1.25);

      &:after {
        content: " ";
        background-color: #fff;
        display: inline-block;
        margin-left: 10px;
        padding-bottom: 5px;
        color: #221d3e;
        width: 17px;
        height: 17px;
        visibility: visible;
        border: 2px solid #181432;
        padding-left: 3px;
        border-radius: 5px;
      }

      &:checked:after {
        content: "x";
        font-weight: bold;
        font-size: .8rem;
        color: #181432;
        position: relative;
        padding-bottom: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: left;
        cursor: pointer;
      }
    }

    pre {
      padding: 1rem;
      border-radius: 3px;
      margin-top: 1rem;
      overflow: auto;
      display: block;
      line-height: 1rem;
    }

    ul, ol {
      line-height: 1.5rem;
      padding-left: 1.5rem;

      li {

      }
    }

    img {
      max-width: 100%;
      border-radius: 5px;
    }
  }
}

.sellix-overlay {
  backdrop-filter: blur(1px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 999999999;
  background-color: #030a16c4 !important;
}
.invoice-details {
  position: relative;
  margin-bottom: 2rem;


  &-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--white);
    border-radius: var(--border-radius);
    margin-top: 1rem;
    box-shadow: 0 1px 3px rgba(7, 14, 35, 0.06), 0 2px 8px -1px rgba(7, 14, 35, 0.05);

    &-title {
      padding: 1.25rem;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: var(--black9);
    }

    &-info {

      & > div {
        display: flex;
        width: 100%;
        border-top: var(--border-width) solid var(--black1);
        padding: 1rem 1.25rem;
        justify-content: space-between;
        color: var(--black4);
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;

        &:first-child {
          border-top: none;
        }

        span:first-child {
          width: 30%;
        }

        span:last-child {
          width: 70%;
          text-align: right;
          color: var(--black9);
        }
      }
    }
  }


  .flag-icon {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
  }

  .paypal-badge {
    background-color: rgba(28, 142, 196, 0.22);
    color: rgba(28, 142, 196, 1);
    font-size: 12px;
    white-space: nowrap;
    width: auto !important;
  }

  .proxy-label {
    color: var(--red7) !important;
    background-color: var(--red0);
    padding: 3px 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    width: auto !important;
  }
}

.invoice-details-block {

  &-custom {
    overflow: auto;
    padding: 0;

    .sellix-table {
      box-shadow: none;

      table {
        thead th {
          padding: .5rem 0.75rem;
        }
        tbody tr td {
          border-top-color: var(--border-color) !important;
          border-top-style: var(--border-style) !important;
        }
      }

      &-container {
        padding: 0;
      }
    }
  }
}



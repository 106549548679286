.invoice-details {
  position: relative;
  margin-bottom: 2rem;

  &-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--white);
    border-radius: var(--border-radius);
    margin-top: 1rem;
    box-shadow: 0 1px 3px rgba(7, 14, 35, 0.06), 0 2px 8px -1px rgba(7, 14, 35, 0.05);

    &-title {
      padding: 1.25rem;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: var(--black9);
    }

    &-goods {
      display: flex;
      width: 100%;
      border-top: var(--border-width) var(--border-style) var(--border-color);
      padding: 1rem 1.25rem;
      justify-content: space-between;
      color: var(--black4);
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      flex-direction: column;

      &-cart {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        max-height: 20rem;
        overflow-y: scroll;
        border-bottom: var(--border-width) solid var(--border-color);

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }

        &-title {
          color: var(--black9);
          font-weight: 600;
          margin-bottom: 1rem;
        }
      }

      span:first-child {
        width: 30%;
      }

      span:last-child {
        color: var(--black9);
        width: 70%;
        text-align: right;
      }
    }
  }

  label {
    margin-bottom: 0;
  }


  pre {
    font-size: .9rem;
    line-height: 1.2rem;
    max-height: 12rem;
    padding: 0;
    margin: 0;
    background: none;
  }

}

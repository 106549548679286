.rcon-gaming-screen {
    .screen-filters {
        width: 100%;
        justify-content: flex-start;
    }

    .execute-command-btn {
        padding: 0.5rem 0.75rem !important;
        height: 32px;

        border-radius: 8px;
        border: var(--border-width) var(--border-style) var(--border-color);
        box-shadow: 0 1px 2px 0 rgba(18, 20, 23, 0.06);

        &:disabled {
            border: var(--border-width) var(--border-style) var(--border-color);
        }
    }
}
.dark {
  .select-container {
    background-color: var(--black1);

    svg {

    }
  }
}

.select-container {
  position: relative;
  background-color: var(--white);
  border-radius: var(--border-radius);
  max-height: 2.25rem;
  height: 2.25rem;

  svg {
    position: absolute;
    top: calc(50% - 10px);
    right: 0.75rem;
    z-index: 0;
  }
}

.select {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 2.375rem;
  transition: border .3s ease;
  outline: none;
  margin: 0;
  color: var(--black5);
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 17px;

  border: var(--border-width) var(--border-style) var(--border-color);
  box-shadow: 0 1px 2px rgba(18, 20, 23, 0.06);
  border-radius: var(--border-radius);

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;

  &.extra-large {
    max-height: 2.5rem;
    height: 2.5rem;
  }

  &.large {
    max-height: 2.25rem;
    height: 2.25rem;
  }

  &.medium {
    max-height: 2rem;
    height: 2rem;
    font-weight: 500 !important;
  }

  &.small {
    max-height: 1.75rem;
    height: 1.75rem;
  }

  &:active,
  &:focus,
  &:hover {
    border-color: var(--purple6);
  }

  &::-ms-expand {
    display: none;
  }
}
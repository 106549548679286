.dashboard-info {
  &-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &-card {
    background: var(--white);
    box-shadow: 0 1px 3px rgba(7, 14, 35, 0.06), 0 2px 8px -1px rgba(7, 14, 35, 0.05);
    border-radius: var(--border-radius);
    padding: 1.25rem;
    width: calc(50% - .5rem);
    color: var(--black9);

    @media (max-width: 768px) {
      width: 100%;
    }

    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: .5rem;
    }

    &-info {
      font-style: normal;
      font-size: 24px;
      line-height: 29px;
      gap: .5rem;
      display: flex;
      align-items: flex-end;

      font-weight: 600;
      color: var(--purple5);

      &-add {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        color: var(--black4);
        display: flex;
        align-items: flex-end;
        gap: .25rem;

        & > span {
          line-height: 23px;
          color: var(--black9) !important;
        }
      }
    }

    &-buttons {

    }
  }


  &-product {
    display: flex;
    flex-direction: column;
    min-height: 2.25rem;
    gap: .25rem;

    span {
      color: var(--black4);
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      display: flex;
    }

    a {
      font-weight: 400;
      color: var(--brand-color) !important;
      font-size: 12px;
    }
  }

  &-quantity {
    min-height: 2.25rem;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &-price {
    min-height: 2.25rem;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &-percentage {
    min-height: 2.25rem;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }


  &-table {
    margin-top: 1rem;
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: 0 1px 3px rgba(7, 14, 35, 0.06), 0 2px 8px -1px rgba(7, 14, 35, 0.05);

    .sellix-table-container {
      border-radius: 0;
      box-shadow: none;
    }

    .sellix-table table tbody tr td {
      cursor: pointer;
      border-top-color: var(--border-color) !important;
      border-top-style: var(--border-style) !important;
    }

    .sellix-table table thead tr {
      &:last-child {
        th {
          text-align: left;
        }
      }
    }

    & > span {
      padding: 1.25rem;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      border-bottom: var(--border-width) var(--border-style) var(--border-color) !important;
      color: var(--black9);
    }
  }
}


.custom .affiliate-invoices-list .hovered {
  background: var(--brand-hover-background) !important;
}

.affiliate-invoices-list .hovered {
  background: var(--black0) !important;
}

.custom .requests-list .hovered {
  background: var(--brand-hover-background) !important;
}

.requests-list .hovered {
  background: var(--black0) !important;
}
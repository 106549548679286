.custom .request-withdraw-modal-list {
  background: var(--brand-hover-background);
}

.custom .request-withdraw-modal-list-divider {
  border-top: var(--border-width) solid var(--brand-hover-background);
}

.custom .request-withdraw-modal-list-body-item:hover {
  background: var(--brand-hover-background);
}

.custom .request-withdraw-modal-list-header-select {
  color: var(--brand-color);
}

.request-withdraw-modal {
  &-list {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background: var(--black2);
    border-radius: .5rem;
    padding: 1rem 0;

    &-header {
      padding: 0 1.5rem;
      padding-bottom: .5rem;
      user-select: none;

      &-select {
        white-space: nowrap;
        color: var(--purple6);
        cursor: pointer;
        text-decoration: underline;
      }

      & > div {
        color: var(--black9);
      }
    }

    &-footer {
      display: flex;
      padding: 1rem 1.5rem 0 1.5rem;
      user-select: none;

      & > div {
        color: var(--black9);
      }
    }

    &-divider {
      border-top: 2px solid var(--black6);
      width: calc(100% - 2rem);
      margin: 0 auto;
    }

    &-body {
      display: flex;
      flex-direction: column;
      padding: 0 1rem .5rem 1rem;
      margin-bottom: .5rem;
      user-select: none;

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: .5rem;
        padding: .5rem;
        cursor: pointer;
        color: var(--black9);

        &.active {
          .request-withdraw-modal-list-body-item-checkbox {
            background: var(--purple6);
          }
        }

        &:hover {
          background: var(--black0);
        }

        &-checkbox {
          width: 1.25rem;
          height: 1.25rem;
          border-radius: 50%;
          background: var(--black9);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-message {
          padding-left: .5rem;
          margin-bottom: .5rem;
          margin-top: .5rem;
          color: var(--black9);
          font-size: 13px;
          font-weight: 400;

          & > span {
            display: flex;
            margin-top: .25rem;
            margin-bottom: .25rem;
            font-size: 12px;
            color: var(--black9);
          }
        }
      }
    }

  }
}







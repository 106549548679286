.invoice-details {

  &-products {
    flex-wrap: wrap;
    width: 100% !important;

    &-header {
      display: flex;
      border-bottom: var(--border-width) var(--border-style) var(--border-color);

      & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: .5rem 1.25rem;
        color: var(--black4);
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;

        &:nth-child(1) {
          width: 2.5rem;
          padding-right: .5rem;
        }
        &:nth-child(2) {
          width: 45%;
          padding-left: .5rem;
        }
        &:nth-child(3) {
          width: 15%;
          text-align: left;
        }
        &:nth-child(4) {
          width: 10%;
        }
        &:nth-child(5) {
          width: 15%;
        }
        &:nth-child(6) {
          width: 15%;
        }
        &:nth-child(7) {
          width: 5%;
        }
      }
    }

    &-item {
      display: flex;
      width: 100%;

      &-copy {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 17px;
        height: 18px;
        cursor: pointer;
        transition: all .3s ease;

        svg {
          transition: all .3s ease;
        }

        &:hover {
          svg * {
            stroke: var(--purple9);
          }
        }
      }

      &:last-child {
        border-radius: 0 0 var(--border-radius) var(--border-radius);
      }

      &:hover {
        background: var(--black0);
        cursor: pointer;
      }

      & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: .75rem 1.25rem;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: var(--black9);
        text-align: right;

        &:nth-child(1) {
          width: 2.5rem;
          padding-right: .5rem;
        }
        &:nth-child(2) {
          width: 45%;
          padding-left: .5rem;
        }
        &:nth-child(3) {
          width: 15%;
          text-align: left;
        }
        &:nth-child(4) {
          width: 10%;
        }
        &:nth-child(5) {
          width: 15%;
        }
        &:nth-child(6) {
          width: 15%;
        }
        &:nth-child(7) {
          width: 5%;
        }
      }

      &:first-child {
        border-bottom: var(--border-width) var(--border-style) var(--border-color);
      }
    }
  }

}
.dropdown-custom-item {

    &-option {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 0 0.5rem;
        height: 36px;
        cursor: pointer;
        border-radius: .375rem;
        gap: .75rem;
        color: var(--black5);
        font-weight: 500;

        &.selected {
            background-color: var(--black1);
            color: var(--black9);
        }

        &:hover {
            background-color: var(--black1);
            color: var(--black9);
        }

        & img {
            width: 1.75rem;
            height: 1.125rem;
        }
    }

    &-value {
        display: flex;
        width: 100%;
        padding: 0.25rem 1rem;
        cursor: pointer;
        position: absolute;
        pointer-events: none;
        align-items: center;
        justify-content: flex-start;
        color: var(--black9);
        gap: .75rem;
        font-weight: 500;

        & img {
            width: 1.75rem;
            height: 1.125rem;
        }
    }

}



.layout {
    width: 100%;
    height: 100%;
}

.page {
    display: flex;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    justify-content: space-between;
    padding: 1.5rem;
    min-height: calc(100vh - 3.5rem);

    @media (max-width: 768px) {
        padding: 1rem;
    }
}

.navigation {
    max-width: 200px;
    width: 25%;
    padding-top: 1.5rem;
    margin-right: 1.5rem;
    transition: transform .3s ease;
    z-index: 99;
    white-space: nowrap;

    &-header {
        max-width: none;
        width: auto;
        padding-top: 0;
        margin-right: 0;
        transition: transform .3s ease;
        z-index: 99;
        white-space: nowrap;
        display: flex;
        gap: .5rem;

        @media (max-width: 915px) {
            gap: 0;
        }

        @media (max-width: 883px) {
            gap: .25rem;
        }

        .navigation-item {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: var(--black5);
            padding: .5rem .75rem .5rem .5rem;
            border-radius: var(--border-radius);
            gap: .5rem;
            cursor: pointer;

            @media (max-width: 883px) {
                padding-left: .5rem !important;
                padding-right: .5rem !important;
            }


            &.active {
                background: var(--purple0);
                color: var(--black9);

                svg {
                    * {
                        stroke: var(--purple6);
                    }
                }

                &:hover {
                    background: var(--purple0);
                    color: var(--black9);
                }
            }

            &:hover {
                color: var(--black9);

                svg {
                    * {
                        stroke: var(--black9);
                    }
                }
            }

            svg {
                margin-right: 0 !important;


                @media (max-width: 1111px) {
                    display: none;
                }
            }
        }
    }

    &.show {
        @media (max-width: 768px) {
            position: absolute;
            background: var(--black0);
            left: 0;
            padding: 1rem;
            width: 100%;
            height: calc(100% - 3.5rem);
            max-width: 100%;
            padding-top: 0;

            a {
                display: flex;
                align-items: center;
            }

            svg {
                color: var(--black9);
            }
        }
    }

    &.hide {
        @media (max-width: 768px) {
            position: absolute;
            background: var(--black0);
            left: 0;
            padding: 1rem;
            width: 100%;
            height: calc(100% - 3.5rem);
            transform: translateX(-110%);
        }
    }

    & > button {
        background: transparent;
    }
    & > a, & > button, .navigation-item {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: var(--black5);
        padding: .5rem .75rem .5rem .5rem;
        border-radius: var(--border-radius);


        @media (max-width: 1200px) {
            font-size: 13px !important;
            padding: .5rem 0.25rem .5rem 0.25rem !important;
        }

        &.active {
            background: var(--purple0);
            color: var(--black9);

            svg {
                * {
                    stroke: var(--purple6);
                }
            }

            &:hover {
                background: var(--purple0);
                color: var(--black9);
            }
        }

        &:hover {
            color: var(--black9);

            svg {
                * {
                    stroke: var(--black9);
                }
            }
        }

        svg {
            margin-right: 1rem;
        }
    }
}

.content {
    width: 100%;
    border-left: none;
    padding-left: 0;
}

.content-header {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 1.5rem;
}
@import "../../assets/styles/index.css";

.dark {
  .modal {
    &-icon {
      &.success {
        background: var(--green0);
      }

      &.error {
        background: var(--red0);
      }

      &.info {
        background: var(--purple0);
      }
    }

    &-footer {
      button.warning {
        color: var(--black9);
      }
      button.secondary {
        background: var(--black1);
      }
    }
  }
}

.modal {
  width: 100%;
  max-width: 640px;
  padding: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--white);
  border-radius: var(--border-radius);
  position: absolute;
  max-height: 90vh;
  overflow: auto;
  border: var(--border-width) var(--border-style) var(--border-color);
  outline: none;

  &-item-text {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    background: var(--black1);
    padding: 1rem;
    border-radius: var(--border-radius);
  }

  &-container {
    padding: 1.75rem;
  }

  &:hover {
    outline: none;
  }

  &.visible {
    overflow: visible;
  }

  @media (max-width: 768px) {
    width: calc(100% - 2rem);
    transform: translate(-50%, 0%);
    top: 1rem;
  }

  &.confirm {
    max-width: 400px;
    overflow: hidden;
  }

  &-icon {
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 1.5rem;

    &.success {
      background: var(--green1);
    }

    &.error {
      background: var(--red1);
    }

    &.info {
      background: var(--purple1);
    }
  }

  &-header {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--black9);
    margin-bottom: .5rem;
    padding: 0;
    border: 0;
    display: flex;
    justify-content: center;
  }

  &-description {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: var(--black5);

    b {
      font-weight: 600;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border-top: none;
    margin-top: 1.75rem;
    padding: 0;
    gap: 1rem;

    button {
      margin: 0;
      min-height: 2.25rem;
      width: calc(50% - .25rem);
      border-radius: var(--border-radius);
      border: var(--border-width) var(--border-style) var(--border-color);
      box-shadow: 0 1px 2px rgba(18, 20, 23, 0.06);
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;

      &.warning {
        background: var(--red6);
        border-color: var(--red6);
      }
      &.white {
        font-weight: 500;
        color: var(--black9);
        background: var(--white);
      }
    }
  }

  .sellix-text {
    color: var(--black6);

    h1 {
      font-size: 1.6rem;
      line-height: 3rem;
    }
    h2 {
      font-size: 1.4rem;
      line-height: 2.5rem;
    }
    h3 {
      font-size: 1.3rem;
      line-height: 2.4rem;
    }
    h4 {
      font-size: 1.1rem;
      line-height: 2rem;
    }
    h6 {
      font-weight: 500;
    }

    p {
      font-size: .9rem;
      margin: 0;
      line-height: 1.2rem;
      margin-bottom: .6rem;
      color: var(--black6);
    }

    input[type=checkbox] {
      width: 30px;
      height: 20px;
      cursor: pointer;
      font-size: 21px;
      visibility: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem -2rem;
      transform: scale(1.25);

      &:after {
        content: " ";
        background-color: #fff;
        display: inline-block;
        margin-left: 10px;
        padding-bottom: 5px;
        color: #221d3e;
        width: 17px;
        height: 17px;
        visibility: visible;
        border: 2px solid #181432;
        padding-left: 3px;
        border-radius: 5px;
      }

      &:checked:after {
        content: "x";
        font-weight: bold;
        font-size: .8rem;
        color: #181432;
        position: relative;
        padding-bottom: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: left;
        cursor: pointer;
      }
    }

    pre {
      padding: 1rem;
      border-radius: 3px;
      margin-top: 1rem;
      overflow: auto;
      display: block;
      line-height: 1rem;
    }

    ul, ol {
      line-height: 1.5rem;
      padding-left: 1.5rem;

      li {

      }
    }

    img {
      max-width: 100%;
      border-radius: 5px;
    }
  }
}

.sellix-overlay {
  backdrop-filter: blur(1px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 999999999;
  background-color: #030a16c4 !important;
}
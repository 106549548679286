.last-header-block {
  text-align: right !important;
  padding-right: 1.5rem !important;
}

.dark {
  .sellix-table table tbody tr td {
    border-top: var(--border-width) var(--border-style) var(--border-color);
  }
  .sellix-table table thead tr th {
    background: var(--black1);
  }

  .sellix-table-pagination {
    border-top: var(--border-width) var(--border-style) var(--border-color) !important;
    background: var(--black1);
  }

  .page-size-container {
    .select-container {
      background-color: var(--white);
    }
  }
}

.sellix-table {
  width: 100%;
  overflow-x: auto;

  &-clear {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;

    &-icon {
      margin-bottom: 1.5rem;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 100%;
      background: var(--purple0);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &-title {
      margin-bottom: .5rem;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: var(--black9);
    }
    &-text {
      margin-bottom: 1.5rem;
      color: var(--black4);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &-container {
    border-radius: var(--border-radius);
    box-shadow: 0 1px 3px rgba(7, 14, 35, 0.06), 0 2px 8px -1px rgba(7, 14, 35, 0.05);

    &.cursor-pointer table tr td {
      cursor: pointer;
    }

    .sellix-table {
      background: var(--white);
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }

    &.skip-pagination {
      border-radius: var(--border-radius);
      box-shadow: none;

      .sellix-table {
        border-radius: var(--border-radius);
        box-shadow: 0 1px 3px rgba(7, 14, 35, 0.06), 0 2px 8px -1px rgba(7, 14, 35, 0.05);

        table {
          border-radius: var(--border-radius);
        }
      }
    }
  }

  tbody {

    tr {
      &:nth-last-child(-n+3) {
        .sellix-table-actions-modal {
          flex-direction: row;
          top: -1rem;
          right: 1.5rem;
          width: auto;
          transform: none;
        }
      }
    }
  }

  table {
    border-radius: var(--border-radius);
  }

  &-container {
    width: 100%;
    max-width: 100%;
  }

  @media (max-width: 768px) {
    overflow: scroll;
  }

  &-nothing {
    padding: 2rem 1.5rem;
    color: var(--black9);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &-actions {
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding: .25rem .5rem;

    img {
      &:hover {
        & + div {
          display: flex;
        }
      }
    }

    & > div {
      position: absolute;
      top: 1rem;
      display: none;
    }

    & > img {
      cursor: pointer;
    }

    &:hover {
      .sellix-table-actions-modal {
        display: flex;
      }
    }

    &-modal {
      z-index: 9;
      position: absolute;
      top: calc(100% - .5rem);
      right: -5rem;
      display: none;
      flex-direction: column;
      width: 8rem;
      background: var(--white);
      transform: translateX(-50%);

      box-shadow: 0 4px 24px -4px rgba(0, 0, 0, 0.08);
      border-radius: var(--border-radius);
      font-size: 14px;
      margin-top: .5rem;
      overflow: hidden;
      background: var(--white);
      border: none;


      @media (max-width: 768px) {
        flex-direction: row;
        width: auto;
        top: calc(50% - 25px) !important;
        right: 2rem;
        transform: none;
      }

      & > div, & > a, & > span {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 0.75rem;
        height: 36px;
        line-height: 36px;
        color: var(--black4) !important;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        white-space: nowrap;

        span {
          margin-left: .5rem;
        }


        @media (max-width: 768px) {
          padding: 1rem;

          i {
            margin-right: 0 !important;
            font-size: 1rem;
          }

          & > span {
            display: none;
          }
        }

        &.delete-icon {
          color: var(--red6) !important;

          &:hover {
            color: var(--red6) !important;
            background: var(--red0);
          }
        }

        &:hover {
          color: var(--black9) !important;
          background: var(--black1);
        }

        img {
          margin-right: .75rem;
        }

        i {
          color: var(--black9);
          margin-right: .75rem;
        }
      }
    }

  }

  &-pagination {
    display: flex;
    justify-content: space-between;
    color: var(--black6);
    padding: 1rem 1.25rem;
    list-style: none;
    border-top: var(--border-width) solid var(--black0) !important;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    background-color: var(--white);

    @media (max-width: 768px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;

      .page-size-container {
        margin-bottom: 0;
      }

      & > div {
        width: 100%;
        justify-content: center;
        margin-bottom: 1rem;
      }
    }

    button {
      width: 2rem;
      height: 2rem;
      padding: 0 !important;
    }

    button.active {
      border-color: var(--purple6);
      border-width: 2px;

      &:hover {
        background-color: var(--white);
      }
    }

    button.more svg {
      margin-top: 0.25rem;
    }

    .page-size-container {
      padding-right: 1rem;
      display: flex;
      flex-wrap: wrap;

      .select-container {
        max-height: 2rem;
        height: 2rem;
      }

      > span {
        font-size: 13px;
        font-weight: 500;
        color: var(--black4);
      }
    }

    & > div {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      @media (max-width: 768px) {
        flex-wrap: wrap;
      }
    }

    .sellix-select {
      width: 7rem;
      height: 2rem;
      margin-left: 1rem;
      font-size: .8rem;
    }

    .sellix-input {
      width: 5rem;
      height: 2rem;
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      background: var(--white);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--black9);


      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type=number]{
        -moz-appearance: textfield;
      }
    }
  }

  table {
    background: var(--white);
    border-radius: var(--border-radius);
    margin-bottom: 0;
    table-layout: auto;
    width: 100%;

    &.last-right {
      thead tr th:last-child {
        text-align: right;
      }
      tbody tr td:last-child {
        text-align: right;
      }
    }

    &.last-center {
      thead tr th:last-child {
        text-align: center;
      }
      tbody tr td:last-child {
        text-align: center;
      }
    }

    thead {
      th {
        background: var(--white);
        color: var(--black4);
        padding: 0.875rem .75rem 0.875rem 1.25rem;
        border-bottom: none !important;
        border-top: none !important;
        cursor: pointer;
        white-space: nowrap;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;

        & > span {
        //display: none;
        }

        &:first-child {
          padding-left: 1.25rem !important;
          border-radius: var(--border-radius) 0 0 0;
        }
        &:last-child {
          padding-right: 1rem;
          border-radius: 0 var(--border-radius) 0 0;
        }

        &:focus {
          outline: none;
        }
      }
    }

    tbody {

      tr {

        &:last-child {
          td:first-child {
            border-radius: 0 0 0 var(--border-radius);
          }
          td:last-child {
            border-radius: 0 0 var(--border-radius) 0;
          }
        }

        &:hover {
          background: var(--black0);

          td {
            color: var(--black9);
          }
        }

        td {
          vertical-align: middle;
          padding: 13px 0 !important;
          padding-left: 1.25rem !important;
          border: none;
          border-top: var(--border-width) solid var(--black0) !important;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: var(--black9);

          &:first-child {
            padding-left: 1.25rem !important;
          }
          &:last-child {
            padding-right: 1.25rem !important;
          }

          a {
            color: var(--black9);

            &:hover {
              color: #6461FC;
              text-decoration: none;
            }

            b {
              font-weight: 600;
            }
          }
        }
      }
    }
  };


  &::-webkit-scrollbar {
    width: 3px !important;
    height: 3px !important;
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.3) !important;
  }

}

.license-screen {
    width: 100%;
    position: relative;
    color: var(--black6);
    margin-bottom: 3rem;

    &-container {
        width: 100%;
    }

    @media (max-width: 768px) {
        .screen-filters {
            gap: 0.5rem;
            .select-container {
                margin-right: 0 !important;
            }
            .screen-search {
                margin-bottom: 0;
            }
        }
    }

    &-info {
        border: var(--border-width) var(--border-style) var(--border-color);
        background: var(--black9);
        border-radius: var(--border-radius);
        overflow: hidden;
        margin-top: 1rem;
        padding: 1rem;
    }

    table tbody tr a {
        color: var(--purple6) !important;
    }
}

.badge {
    width: fit-content;
    padding-left: 6px;
    padding-right: 6px;
    height: 20px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--purple6);

    img {
        margin-right: .3rem;
    }
}

.badge-pending, .badge-waiting-for-seller-response, .badge-file, .badge-ip {
    background: var(--orange0) !important;
    color: var(--orange6) !important;
}

.badge-asn, .badge-info-card, .badge-info {
    background: var(--orange0) !important;
    color: var(--orange6) !important;
}

.badge-cancelled, .badge-voided, .badge-canceled, .badge-disabled, .badge-rejected, .badge-error, .badge-failed, .badge-none, .badge-unverified, .badge-bad, .badge-await_canceling {
    background: var(--red0) !important;
    color: var(--red6) !important;
}

.badge-completed, .badge-subscription, .badge-active, .badge-approved, .badge-isp, .badge-success, .badge-verified, .badge-good {
    background: var(--green0) !important;
    color: var(--green6) !important;
}

.badge-ended {
    background: var(--purple0) !important;
    color: var(--purple6) !important;
}

.badge-confirmation, .badge-country, .badge-partial {
    background: var(--cyan0) !important;
    color: var(--cyan6) !important;
}

.badge-trialing, .badge-waiting, .badge-dns, .badge-serials, .badge-developer {
    background: var(--blue0) !important;
    color: var(--blue6) !important;
}

.badge-reversed, .badge-dynamic, .badge-host, .badge-reason {
    background: var(--pink0) !important;
    color: var(--pink6) !important;
}

.badge-refunded {
    background: #3F4A6014 !important;
    color: #3F4A60 !important;
}

.badge-ongoing, .badge-email, .badge-warning, .badge-service, .badge-sellix {
    background: var(--purple0) !important;
    color: var(--purple6) !important;
}

.badge-mark {
    line-height: 26px;
    text-align: center;
    border-radius: 4px;
    font-size: 16px !important;
    font-weight: normal;
    padding: 3px 10px;
    margin: 2px;
    vertical-align: middle;
    background-color: var(--purple1) !important;
    color: var(--purple6) !important;

    &.gray {
        background-color: var(--purple0)!important;
        color: var(--black6)!important;
    }
}

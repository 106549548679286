@tailwind base;
@tailwind components;
@tailwind utilities;

.new-modal-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    text-align: center;
    margin-bottom: .5rem;
    color: var(--black9);
}
.new-modal-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--black5);
}
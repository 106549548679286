.dark {
  .invoice-details {
    &-info {
      background: var(--black1);
    }
  }
}

.invoice-details {
  position: relative;
  margin-bottom: 2rem;

  &-header {
    background: var(--white);
    box-shadow: 0 1px 3px rgb(7 14 35 / 6%), 0 2px 8px -1px rgb(7 14 35 / 5%);
    border-radius: var(--border-radius);
  }

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    color: var(--black4);
    border-bottom: var(--border-width) var(--border-style) var(--border-color);

    @media (max-width: 768px) {
      flex-wrap: wrap;
      padding: 1rem;

      & > div:last-child {
        margin-top: 1rem;

        .badge {
          margin-left: 0 !important;
        }
      }
    }

    &-image {
      border-radius: var(--border-radius);
      margin-right: .75rem;
      width: 2.25rem;
      height: 2.25rem;
      background: var(--black0);
      color: var(--purple6);
      display: flex;
      align-items: center;
      justify-content: center;

      &-blockchain {
        width: 1rem !important;
        height: 1rem !important;
        position: absolute;
        left: 1.5rem;
        bottom: -0.25rem;
        padding: 2px;
        background: var(--white);
        border-radius: 0.25rem;
        margin-right: 0 !important;

        &:empty {
          display: none !important;
        }

        @media (max-width: 768px) {
          width: 0.85rem !important;
          height: 0.85rem !important;
          left: 1rem;
          margin-right: 0 !important;
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-copy {
      width: 20px;
      min-width: 20px;
      height: 20px;
      cursor: pointer;
      transition: all .3s ease;

      svg {
        transition: all .3s ease;
      }

      &:hover {
        svg * {
          stroke: var(--purple9);
        }
      }
    }

    & > div:first-child {
      display: flex;
      align-items: center;

      span {
        display: flex;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px !important;
        color: var(--black4);
      }

      & div {

        a {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;

          & + span {
            display: flex;
            align-items: center;
            width: 17px;
            height: 18px;
            margin-left: .5rem;
          }
        }
      }
    }
  }

}

.sellix-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: var(--border-width) var(--border-style) var(--border-color);
    box-shadow: inset 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: var(--border-radius);
    background: var(--white);
    width: 100%;
    padding: 0 14px;
    transition: border .3s ease !important;
    height: 40px;
    max-height: 40px;
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--black9);

    &.textarea {
        height: auto;
        max-height: none;
        padding: .75rem 1rem;
        border-radius: var(--border-radius);
        border: var(--border-width) var(--border-style) var(--border-color);
        box-shadow: inset 0 1px 2px rgba(16, 24, 40, 0.05);
        min-height: 80px;
    }

    &.is-invalid {
        border: var(--border-width) solid var(--red6) !important;

        &:hover {
            border-color: var(--red6) !important;
        }
        &:focus {
            border-color: var(--red6) !important;
        }
    }

    &:active {
        border-color: var(--purple6);
    }
    &:focus {
        border-color: var(--purple6);
    }
    &:hover {
        border-color: var(--purple6) !important;
    }

    &::placeholder {
        color: var(--black4);
    }

    &[disabled] {
        background: var(--black0);
        border-color: var(--black2);

        &:focus {
            border-color: var(--black2);
        }
    }
}

.sellix-label {
    margin-bottom: .75rem;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: var(--black5);
    display: flex;

    align-items: center;

    &-subtitle {
        font-size: 11px;
        color: var(--black4);
    }
}


.sellix-note {
    display: block;
    align-items: end;
    margin-top: .75rem;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--black4);

    @media (max-width: 768px) {
        font-weight: 400;
    }

    b {
        color: var(--black9) !important;
        font-weight: 600 !important;
    }
    a {
        color: var(--purple6) !important;
        font-weight: 600 !important;
    }
}

a {
    color: var(--purple6);
}

.invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: var(--red6);
}


.table-mobile-card {
    background: var(--white);
    box-shadow: 0 1px 3px rgb(7 14 35 / 6%), 0 2px 8px -1px rgb(7 14 35 / 5%);
    border-radius: var(--border-radius);
    margin-bottom: 1rem;

    &-header {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        border: var(--border-width) var(--border-style) var(--border-color);

        &-main {
            display: flex;
            flex-direction: column;

            & > a {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: var(--black9);
                display: flex;
            }

            div {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: var(--black4);
                display: flex;
                margin-top: .25rem;
            }
        }

        &-icon {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: var(--border-radius);
            object-fit: fill;
            margin-right: .75rem;
            color: var(--black3);
            border: var(--border-width) var(--border-style) var(--border-color);
            box-shadow: 0 1px 2px 0 #1214170F;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: fill;
                overflow: hidden;
                border-radius: var(--border-radius);
            }
        }
    }

    &-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;

        &-list {
            display: flex;
            flex-direction: column;
            width: 100%;

            & > div {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                color: var(--black4);
                margin-bottom: .75rem;

                &:last-child {
                    margin-bottom: 0;
                }

                & > div {
                    &:first-child {
                        padding-right: 1rem;
                    }
                    &:last-child {
                        color: var(--black9);
                    }
                }
            }
        }

        &-info {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: var(--black9);

            @media (max-width: 360px) {
                div {
                    width: 100%;
                    margin-bottom: .5rem;
                    margin-right: 0 !important;
                    display: flex;
                    justify-content: flex-end;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &-footer {
        display: flex;
        padding: 1rem;
        padding-top: 0;
        justify-content: space-between;

        button {
            width: calc(50% - .5rem);
        }
    }

}
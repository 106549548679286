.dark {
  .screen-search input {
    background: var(--black1);
    //border-color: var(--black2);
  }
}

.screen-search {
  min-width: 25%;
  position: relative;

  &.long {
    min-width: 40%;
  }

  &.small {
    padding: 0;

    input {
      max-height: 2rem;
    }
  }

  input {
    padding-left: 2.5rem;
    background: var(--white);
    box-shadow: 0 1px 2px rgba(18, 20, 23, 0.06);
    height: 2.25rem;
    max-height: 2.25rem;

    &::placeholder {
      color: var(--black3);
    }
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translate(1rem, -50%);
    left: -.2rem;
  }
}
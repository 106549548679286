.react-code-input {
    width: 100%;

    input {
        -webkit-appearance: none !important;
        appearance: none !important;
        box-shadow: none !important;
        border: var(--border-width) var(--border-style) var(--border-color) !important;
        background: var(--black0) !important;
        border-radius: 0 !important;
        color: var(--black9) !important;
        transition: border 0.3s ease !important;
        height: 48px !important;
        outline: none !important;
        border-right: none !important;
        text-align: center;
        margin: 0 !important;
        padding: 0 0.25rem !important;
        font-size: 32px;
        width: calc(100%/8) !important;
        -moz-appearance: textfield !important;

        &:first-child {
            border-radius: var(--border-radius) 0 0 var(--border-radius) !important;
        }
        &:last-child {
            border-radius: 0 var(--border-radius) var(--border-radius) 0 !important;
            border-right: var(--border-width) var(--border-style) var(--border-color) !important;
        }

        &[type="number"] {
            -moz-appearance: textfield !important;
        }
        &[type="number"]:hover,
        &[type="number"]:focus {
            -moz-appearance: textfield !important;
        }

        &[type='number']::-webkit-inner-spin-button,
        &[type='number']::-webkit-outer-spin-button,
        &[type='number']:hover::-webkit-inner-spin-button,
        &[type='number']:hover::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: textfield;
            appearance: none;
            margin: 0;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &[type=number] {
            -moz-appearance: textfield !important;
        }
    }
}

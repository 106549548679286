.payment-methods-screen {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .screen-header {
    padding: 0;
  }

  .payment-method {
    &-type {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
    }
    &-delete-btn {
      color: var(--red6);
      padding: 0.25rem;
      border-radius: 0.25rem;

      &:hover {
        background-color: var(--black1);
      }
    }
  }

  .payment-methods {
    &-block {
      display: flex;
      gap: 0.75rem;
      justify-content: center;

      background: var(--white);
      border-radius: var(--border-radius);
      padding: 1.25rem;
      box-shadow: 0 1px 2px rgba(18, 20, 23, 0.06);
    }

    &-setup-message {
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &-icon {
        width: 120px;
        height: 120px;
        border-radius: 10rem;
        background-color: var(--green0);

        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-title {
        color: var(--black9);
        text-align: center;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    &-card {
      max-width: fit-content;
      padding: 0.5rem;
      border-radius: var(--border-radius);
      cursor: pointer;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: .25rem;

      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: var(--black9);

      &:hover {
        background-color: var(--black0);
      }

      &.active {
        background-color: var(--purple0);
      }

      svg {
        height: 64px;
        width: 64px;
      }
    }
    &-title {
      .sellix-label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.125rem;
      }
    }
    &-stripe-card {
      width: 50%;

      @media (max-width: 768px) {
        width: 100%;
      }

      .stripe-form {
        margin: 0;
      }
    }

    &-paypal-card {
      width: 50%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
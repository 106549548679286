.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 5rem 8.5rem 5rem;
    height: 100%;

    &-icon {
        margin-bottom: 1rem;
    }

    &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: var(--black9);
        margin-bottom: .25rem;
    }

    &-text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--black4);
    }
}
.invoice-details {
  position: relative;
  margin-bottom: 2rem;


  .flag-icon {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
  }

  .paypal-badge {
    background-color: rgba(28, 142, 196, 0.22);
    color: rgba(28, 142, 196, 1);
    font-size: 12px;
    white-space: nowrap;
    width: auto !important;
  }

  .proxy-label {
    color: var(--red6) !important;
    background-color: var(--red0);
    padding-left: 6px;
    padding-right: 6px;
    height: 20px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    width: auto !important;
  }
}

.min-w-\[150px\] {
    min-width: 150px;
}
div.min-h-\[36px\] {
    min-height: 36px !important;
    height: 36px !important;
}

.text-nowrap {
    white-space: nowrap;
}

.switch-button {
    width: auto;
    height: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    border: var(--border-width) var(--border-style) var(--border-color);
    box-shadow: 0 1px 2px rgba(18, 20, 23, 0.06);
    border-radius: var(--border-radius);

    button {
        height: 100%;
        background-color: transparent;
        font-weight: 500;
        color: var(--black4);

        &.active {
            color: var(--purple6);
        }

        &:first-child {
            padding-left: 1rem;
            padding-right: 0.5rem;
        }
        &:last-child {
            padding-left: 0.5rem;
            padding-right: 1rem;
        }

        @media (max-width: 768px) {
            width: 50%;
        }
    }

    .vertical-line {
        width: 1px;
        height: 0.75rem;
        background-color: var(--black2);
    }
}

.invoice-list-screen {
    width: 100%;
    padding-bottom: 1rem;

    .screen-filters {

        & > div {
            display: flex;
        }

        @media (max-width: 768px) {
            flex-wrap: wrap;
            gap: 0.5rem;

            .screen-search {
                margin-bottom: 0;
            }

            & > div {
                width: 100%;

                & > div {
                    width: 100%;
                }

                .switch-button {
                    margin-right: 0 !important;
                }

                .select-container {
                    &:last-child {
                        margin-right: 0 !important;
                    }
                }
            }
        }
    }
}

.invoice-list-screen {
    .title {
        white-space: nowrap;
    }
}

.invoice-list-filters {
    display: flex;
    gap: .5rem;

    @media (max-width: 768px) {
        & > div {
            width: 50%;
        }
    }
}

.invoice-list-icon {
    border-radius: var(--border-radius);
    width: 2.25rem;
    min-width: 2.25rem;
    height: 2.25rem;
    margin-right: .75rem;
    color: var(--black3);
    box-shadow: 0 1px 2px rgba(18, 20, 23, 0.06);
    border: var(--border-width) var(--border-style) var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.invoice-list-id {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--black4);
}

.bill-screen {
    width: 100%;
    position: relative;
    color: var(--black6);
    margin-bottom: 3rem;

    .screen-filters {

        .sellix-subscriptions-bills {
            margin-left: .5rem;
            padding: 0;
        }
    }

    @media (max-width: 768px) {
        .screen-filters {

            .screen-search {
                margin-bottom: 0;
            }

            .select-container {
                margin-right: 0 !important;
            }

            .sellix-subscriptions-bills {
                margin-left: 0 !important;
            }

            & > button {
                width: 100%;
                margin-top: 0;
                margin-left: 0 !important;
            }
        }
    }

    &-container {
        width: 100%;
    }

    &-info {
        border: var(--border-width) var(--border-style) var(--border-color);
        background: var(--black9);
        border-radius: var(--border-radius);
        overflow: hidden;
        margin-top: 1rem;
        padding: 1rem;
    }
}

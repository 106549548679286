.invoice-details-rewards {
  &-header {
    display: flex;
    padding: 0.625rem 1.25rem;
    border-bottom: var(--border-width) var(--border-style) var(--border-color);

    & > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: var(--black4);
      font-weight: 500;
      font-size: 13px;
      line-height: normal;

      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 45%;
      }
      &:nth-child(3) {
        width: 25%;
      }
    }
  }

  &-items {
    display: flex;
    flex-direction: column;
  }

  &-item {
    display: flex;
    padding: 1rem 1.25rem;
    border-bottom: var(--border-width) var(--border-style) var(--border-color);

    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }

    @media (hover: hover) {
      &:hover {
        background: var(--black0);
      }
    }

    & > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: var(--black9);
      font-weight: 500;
      font-size: 13px;
      line-height: normal;

      @media (max-width: 768px) {
        align-items: flex-start;
      }

      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 45%;
      }
      &:nth-child(3) {
        width: 25%;
      }
    }
  }
}


.skeleton1 {
    animation: pulse1 2.3s cubic-bezier(.4,0,.6,1) infinite;
}
.skeleton2 {
    animation: pulse2 1.5s cubic-bezier(.4,0,.6,1) infinite;
}
.skeleton3 {
    animation: pulse3 2.7s cubic-bezier(.4,0,.6,1) infinite;
}
.skeleton4 {
    animation: pulse4 1s cubic-bezier(.4,0,.6,1) infinite;
}
.skeleton5 {
    animation: pulse5 2.3s cubic-bezier(.4,0,.6,1) infinite;
}
.skeleton6 {
    animation: pulse6 2.5s cubic-bezier(.4,0,.6,1) infinite;
}
.skeleton7 {
    animation: pulse7 3.5s cubic-bezier(.4,0,.6,1) infinite;
}


.skeleton {
    background: var(--black3);
    height: 20px;
    border-radius: var(--border-radius);

    &.medium {
        background: var(--black2);
        height: 15px;
        border-radius: .25rem;
    }

    &.small {
        background: var(--black2);
        height: 10px;
        border-radius: .25rem;
    }

    &.static {
        animation: none;
    }
}

@keyframes pulse1 {
    0%, 100% {
        opacity: .1;
    }

    50% {
        opacity: .2;
    }
}
@keyframes pulse2 {
    0%, 100% {
        opacity: .7;
    }

    50% {
        opacity: .9;
    }
}
@keyframes pulse3 {
    0%, 100% {
        opacity: .2;
    }

    50% {
        opacity: .3;
    }
}
@keyframes pulse4 {
    0%, 100% {
        opacity: .3;
    }

    50% {
        opacity: .05;
    }
}
@keyframes pulse5 {
    0%, 100% {
        opacity: .05;
    }

    50% {
        opacity: .2;
    }
}
@keyframes pulse6 {
    0%, 100% {
        opacity: .15;
    }

    50% {
        opacity: .05;
    }
}

@keyframes pulse7 {
    0%, 100% {
        opacity: .2;
    }

    50% {
        opacity: .15;
    }
}




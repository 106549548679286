.affiliate-top {
    &-link {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background: var(--white);
        border-radius: var(--border-radius);
        padding: 1.25rem;
        box-shadow: 0 1px 2px rgba(18, 20, 23, 0.06);
        margin-bottom: 1.5rem;

        &-title {
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
            color: var(--black9);
        }

        &-subtitle {
            font-size: 13px;
            font-weight: 400;
            line-height: 17px;
            color: var(--black9);

            b {
                font-weight: 600 !important;
            }
        }

        &-input {
            display: flex;
            align-content: center;
            justify-content: space-between;
            background: var(--purple0);
            border-radius: var(--border-radius);
            align-items: center;
            padding: 1rem 1.25rem;

            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            color: var(--black9);
        }
    }
}

.affiliate-screen {

    .sellix-table table thead tr {
        th {
            text-align: left;
        }
    }


    &-button {
        border: var(--border-width) var(--border-style) var(--border-color);
        box-shadow: 0 1px 2px rgba(18, 20, 23, 0.06);
        border-radius: var(--border-radius);

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        align-items: center;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
        min-height: 36px;
        padding: 0 .5rem;

        color: var(--black4);
        background: var(--white);
        cursor: pointer;

        display: inline-flex !important;

        svg {
            width: 21px;
            height: 21px;
            margin-right: .5rem;

            & {
               path {
                    stroke: var(--black4);
                }
            }
        }
    }
}
.dropdown-menu-open .header-info {
    & > div {
        &:last-child {
            div > svg {
                transform: rotate(-180deg) !important;
            }
        }
    }
}


.header-profile-dropdown > div.p-2 {
    padding: 0;
}

.header-container .navigation {

    & > .dropdown-menu-open > div + div {
        right: calc(100% + .25rem);
        top: 0;

        @media (max-width: 768px) {
            right: 0;
            top: 0;
        }
    }

}


.color-switch-checkbox {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .color-switch-slider {
        //background-color: #E8E8E9;
    }

    &:focus + .color-switch-slider {
        box-shadow: 0 0 1px #E8E8E9;
    }

    &:checked + .color-switch-slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);

        position: absolute;
        cursor: pointer;
        background-color: #E8E8E9;
        transition: .15s;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik02Ljk5OTcxIDEuNzVDNy4wNzY3MSAxLjc1IDcuMTUzMTMgMS43NSA3LjIyODk2IDEuNzVDNi40Nzk1NyAyLjQ0NjMzIDUuOTk3ODkgMy4zODMyNCA1Ljg2NzYzIDQuMzk3ODhDNS43MzczNyA1LjQxMjUxIDUuOTY2NzggNi40NDA3MiA2LjUxNTk3IDcuMzAzNzZDNy4wNjUxNyA4LjE2NjggNy44OTk0MiA4LjgxMDExIDguODczNzMgOS4xMjE4NkM5Ljg0ODAzIDkuNDMzNjEgMTAuOTAwOCA5LjM5NDA5IDExLjg0OSA5LjAxMDE2QzExLjQ4NDIgOS44ODc3OSAxMC44ODgxIDEwLjY0OTkgMTAuMTI0MiAxMS4yMTUzQzkuMzYwMjIgMTEuNzgwNyA4LjQ1NzE0IDEyLjEyODEgNy41MTEyNCAxMi4yMjA1QzYuNTY1MzQgMTIuMzEyOCA1LjYxMjExIDEyLjE0NjcgNC43NTMyMyAxMS43Mzk4QzMuODk0MzQgMTEuMzMyOSAzLjE2MjAzIDEwLjcwMDUgMi42MzQzOSA5LjkxMDAxQzIuMTA2NzUgOS4xMTk1NCAxLjgwMzU4IDguMjAwNjYgMS43NTcyMyA3LjI1MTRDMS43MTA4NyA2LjMwMjEzIDEuOTIzMDcgNS4zNTgwOSAyLjM3MTE4IDQuNTE5OTZDMi44MTkyOSAzLjY4MTg0IDMuNDg2NSAyLjk4MTA4IDQuMzAxNjUgMi40OTI0M0M1LjExNjgxIDIuMDAzNzcgNi4wNDkzMiAxLjc0NTU2IDYuOTk5NzEgMS43NDUzM1YxLjc1WiIgc3Ryb2tlPSIjMTIxNDE3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CiAgICA8cGF0aCBkPSJNMTAuMTY2NyAzQzEwLjE2NjcgMy4zMDk0MiAxMC4yODk2IDMuNjA2MTcgMTAuNTA4NCAzLjgyNDk2QzEwLjcyNzIgNC4wNDM3NSAxMS4wMjM5IDQuMTY2NjcgMTEuMzMzMyA0LjE2NjY3QzExLjAyMzkgNC4xNjY2NyAxMC43MjcyIDQuMjg5NTggMTAuNTA4NCA0LjUwODM4QzEwLjI4OTYgNC43MjcxNyAxMC4xNjY3IDUuMDIzOTEgMTAuMTY2NyA1LjMzMzMzQzEwLjE2NjcgNS4wMjM5MSAxMC4wNDM4IDQuNzI3MTcgOS44MjQ5NiA0LjUwODM4QzkuNjA2MTcgNC4yODk1OCA5LjMwOTQyIDQuMTY2NjcgOSA0LjE2NjY3QzkuMzA5NDIgNC4xNjY2NyA5LjYwNjE3IDQuMDQzNzUgOS44MjQ5NiAzLjgyNDk2QzEwLjA0MzggMy42MDYxNyAxMC4xNjY3IDMuMzA5NDIgMTAuMTY2NyAzWiIgc3Ryb2tlPSIjMTIxNDE3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==");
        background-repeat: no-repeat;
    }

}


.color-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E8E8E9;
    -webkit-transition: .15s;
    transition: .15s;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik02Ljk5OTcxIDEuNzVDNy4wNzY3MSAxLjc1IDcuMTUzMTMgMS43NSA3LjIyODk2IDEuNzVDNi40Nzk1NyAyLjQ0NjMzIDUuOTk3ODkgMy4zODMyNCA1Ljg2NzYzIDQuMzk3ODhDNS43MzczNyA1LjQxMjUxIDUuOTY2NzggNi40NDA3MiA2LjUxNTk3IDcuMzAzNzZDNy4wNjUxNyA4LjE2NjggNy44OTk0MiA4LjgxMDExIDguODczNzMgOS4xMjE4NkM5Ljg0ODAzIDkuNDMzNjEgMTAuOTAwOCA5LjM5NDA5IDExLjg0OSA5LjAxMDE2QzExLjQ4NDIgOS44ODc3OSAxMC44ODgxIDEwLjY0OTkgMTAuMTI0MiAxMS4yMTUzQzkuMzYwMjIgMTEuNzgwNyA4LjQ1NzE0IDEyLjEyODEgNy41MTEyNCAxMi4yMjA1QzYuNTY1MzQgMTIuMzEyOCA1LjYxMjExIDEyLjE0NjcgNC43NTMyMyAxMS43Mzk4QzMuODk0MzQgMTEuMzMyOSAzLjE2MjAzIDEwLjcwMDUgMi42MzQzOSA5LjkxMDAxQzIuMTA2NzUgOS4xMTk1NCAxLjgwMzU4IDguMjAwNjYgMS43NTcyMyA3LjI1MTRDMS43MTA4NyA2LjMwMjEzIDEuOTIzMDcgNS4zNTgwOSAyLjM3MTE4IDQuNTE5OTZDMi44MTkyOSAzLjY4MTg0IDMuNDg2NSAyLjk4MTA4IDQuMzAxNjUgMi40OTI0M0M1LjExNjgxIDIuMDAzNzcgNi4wNDkzMiAxLjc0NTU2IDYuOTk5NzEgMS43NDUzM1YxLjc1WiIgc3Ryb2tlPSIjMTIxNDE3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CiAgICA8cGF0aCBkPSJNMTAuMTY2NyAzQzEwLjE2NjcgMy4zMDk0MiAxMC4yODk2IDMuNjA2MTcgMTAuNTA4NCAzLjgyNDk2QzEwLjcyNzIgNC4wNDM3NSAxMS4wMjM5IDQuMTY2NjcgMTEuMzMzMyA0LjE2NjY3QzExLjAyMzkgNC4xNjY2NyAxMC43MjcyIDQuMjg5NTggMTAuNTA4NCA0LjUwODM4QzEwLjI4OTYgNC43MjcxNyAxMC4xNjY3IDUuMDIzOTEgMTAuMTY2NyA1LjMzMzMzQzEwLjE2NjcgNS4wMjM5MSAxMC4wNDM4IDQuNzI3MTcgOS44MjQ5NiA0LjUwODM4QzkuNjA2MTcgNC4yODk1OCA5LjMwOTQyIDQuMTY2NjcgOSA0LjE2NjY3QzkuMzA5NDIgNC4xNjY2NyA5LjYwNjE3IDQuMDQzNzUgOS44MjQ5NiAzLjgyNDk2QzEwLjA0MzggMy42MDYxNyAxMC4xNjY3IDMuMzA5NDIgMTAuMTY2NyAzWiIgc3Ryb2tlPSIjMTIxNDE3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-position: calc(100% - 6px) 50%;
    border-radius: 34px;
    background: var(--black0);

    &:before {
        position: absolute;
        content: "";
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik02Ljk5OTg0IDkuMzMzMzJDOC4yODg1IDkuMzMzMzIgOS4zMzMxNyA4LjI4ODY1IDkuMzMzMTcgNi45OTk5OUM5LjMzMzE3IDUuNzExMzMgOC4yODg1IDQuNjY2NjYgNi45OTk4NCA0LjY2NjY2QzUuNzExMTcgNC42NjY2NiA0LjY2NjUgNS43MTEzMyA0LjY2NjUgNi45OTk5OUM0LjY2NjUgOC4yODg2NSA1LjcxMTE3IDkuMzMzMzIgNi45OTk4NCA5LjMzMzMyWiIgc3Ryb2tlPSIjRTE2NzI3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CiAgICA8cGF0aCBkPSJNMy42NzUgMTAuMzI1TDMuMjY2NjcgMTAuNzMzM00xLjc1IDdIMi4zMzMzM0gxLjc1Wk03IDEuNzVWMi4zMzMzM1YxLjc1Wk0xMS42NjY3IDdIMTIuMjVIMTEuNjY2N1pNNyAxMS42NjY3VjEyLjI1VjExLjY2NjdaTTMuMjY2NjcgMy4yNjY2N0wzLjY3NSAzLjY3NUwzLjI2NjY3IDMuMjY2NjdaTTEwLjczMzMgMy4yNjY2N0wxMC4zMjUgMy42NzVMMTAuNzMzMyAzLjI2NjY3Wk0xMC4zMjUgMTAuMzI1TDEwLjczMzMgMTAuNzMzM0wxMC4zMjUgMTAuMzI1WiIgc3Ryb2tlPSIjRTE2NzI3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==");
        background-position: 50%;
        background-repeat: no-repeat;
        height: 18px;
        width: 18px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .15s;
        transition: .15s;
        border-radius: 50%;
        box-shadow: 0 1px 2px rgba(7, 14, 35, 0.12);
    }
}

.color-switch-container {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
    margin: 0;
}

.color-switch {
    padding: 0 0;
    width: 40px;
    margin: 0 .5rem 0 .5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        display: none;
    }

}

.header-menu {
    border-bottom: var(--border-width) var(--border-style) var(--border-color) !important;
    padding-bottom: 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
    margin-bottom: 0.5rem;

    &-header {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: var(--black9);
        border-bottom: var(--border-width) var(--border-style) var(--border-color) !important;
        padding: 1rem;
        margin-bottom: 0.5rem;
    }

    &-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: .5rem;
        padding: 0.5rem 1rem;

        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: var(--black5);
        cursor: pointer;

        &:hover {
            color: var(--black9);
            background-color: var(--secondary-background-hover);
            //background-color: var(--secondary-background-hover);
        }

        &.active {
            color: var(--black9);
            background-color: var(--black3);
            //background-color: var(--secondary-background-hover);
        }
    }
}

.header {
    height: 3.5rem;
    background: var(--white);
    box-shadow: 0 2px 4px -1px #070E2314;

    &-container {
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 0 1.5rem;
        position: relative;

        @media (max-width: 768px) {
            padding: 0 1rem;
        }
    }

    &-logo {
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        text-transform: uppercase;
        color: var(--black9);
        display: flex;
        gap: .5rem;
        align-items: center;

        @media (max-width: 768px) {
            font-size: 16px;
        }

        @media (max-width: 400px) {
            font-size: 15px;
        }

        img {
            height: 30px;
            width: auto;
            border-radius: var(--border-radius);
        }
    }

    &-info {
        display: flex;
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
            transition: all .3s ease;
            & > div {
                &:last-child {
                    div > svg {
                        transform: rotate(-90deg);
                    }
                }
            }
        }

        & > div {
            &:first-child {
                width: 2rem;
                min-width: 2rem;
                height: 2rem;
                font-weight: 700;
                line-height: 2rem;
                border-radius: 100%;
                background: var(--black9);
                margin-right: .5rem;
                color: var(--white);
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: capitalize;

                @media (max-width: 1000px) {
                    display: none;
                }
            }
            &:last-child {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: var(--black9);

                @media (max-width: 1200px) {
                    font-size: 13px;
                }

                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: .5rem;

                    svg {
                        transition: all .3s ease;
                    }
                }

                span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: var(--black3);
                }
            }
        }
    }
}

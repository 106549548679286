.invoice-details {
  position: relative;
  margin-bottom: 2rem;

  &-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &-left {
      width: calc(50% - .5rem);
      margin-top: 1rem;
    }
    &-right {
      width: calc(50% - .5rem);
      margin-top: 1rem;
    }
  }
}


@media (max-width: 768px) {
  .invoice-details {

    &-body {
      flex-wrap: wrap;

      &-left {
        width: 100%;
      }
      &-right {
        width: 100%;
        margin-top: 0;
      }
    }
  }


}


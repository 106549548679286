.invoice-details {
  position: relative;
  margin-bottom: 2rem;

  &-header {
    background: var(--white);
    box-shadow: 0 1px 3px rgb(7 14 35 / 6%), 0 2px 8px -1px rgb(7 14 35 / 5%);
    border-radius: var(--border-radius);
  }

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    color: var(--black4);
    border-bottom: var(--border-width) var(--border-style) var(--border-color);

    @media (max-width: 768px) {
      flex-wrap: wrap;
      padding: 1rem;

      & > div:last-child {
        margin-top: 1rem;

        .badge {
          margin-left: 0 !important;
        }
      }
    }

    &-image {
      border-radius: var(--border-radius);
      margin-right: .75rem;
      width: 2.25rem;
      height: 2.25rem;
      background: var(--black0);
      color: var(--purple6);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
        height: 20px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    & > div:first-child {
      span {
        display: flex;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px !important;
        color: var(--black4);
      }

      a {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }

      & div {
        display: flex;
        flex-direction: column;
      }
    }
  }

}

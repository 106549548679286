.gift-card {
    color: var(--black9);
    box-shadow: 0 2px 8px -1px #070E230D, 0 1px 3px 0 #070E230F;
    border-radius: .5rem;
    border: var(--border-width) var(--border-style) var(--border-color);
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem 1.5rem;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem 1rem;
        gap: .5rem;
    }
}

.gift-card-icon {
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: var(--border-width) var(--border-style) var(--border-color);
    border-radius: .5rem;
    background: var(--black0);
    color: var(--black9);
}

.gift-card-subtext {
    color: var(--black9);
}

.gift-card-control {
    display: flex;
    align-items: center;
    gap: .75rem;

    @media (max-width: 500px) {
        flex-direction: column;
        width: 100%;

        & > div, & > button {
            width: 100%;
        }
    }
}

.gift-card-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    border: var(--border-width) var(--border-style) var(--border-color);
    padding: .75rem;
    border-radius: .5rem;
    box-shadow: 0 1px 2px 0 #1018280D inset;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    background: var(--black0);
    min-width: 230px;

    @media (max-width: 768px) {
        min-width: unset;
    }

    svg {
        cursor: pointer;
        color: var(--black5);
    }

    & + button {
        min-width: 100px;

        @media (max-width: 768px) {
            min-width: unset;
        }
    }
}

.gift-card-title {
    font-size: 20px;
    color: var(--black9);
    font-weight: 600;
    margin-bottom: .75rem;
}

.gift-card-title {

}
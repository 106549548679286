.breadcrumbs-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-right: 2rem;

  .breadcrumbs {
    margin: 0;
    white-space: nowrap;
    display: flex;
    align-items: center;

    &:empty {
      display: none;
    }

    &-copy {
      margin-left: .5rem;
      cursor: pointer;
      transition: all .3s ease;

      svg {
        transition: all .3s ease;
      }

      &:hover {
        svg * {
          stroke: var(--black9);
        }
      }
    }

    .link, svg {
      color: var(--black4);
    }

    .link {
      font-size: 13px;
      font-weight: 500;
    }

    svg {
      margin: 0 0.5rem;
    }
  }

  .title {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    h1 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--black9);
    }

    svg {
      color: var(--black4);
    }
  }
}


@media (max-width: 768px) {
  .breadcrumbs-container {
    padding-right: 0;
    width: 100%;
    gap: 0;

    .breadcrumbs {
      white-space: unset;
      word-break: break-word;

      .link, svg {
      }

      .link {

      }

      svg {

      }
    }

    .title {
      margin-top: .5rem;

      h1 {
        font-size: 20px;
      }
      svg {
        width: 26px;
        min-width: 26px;
      }
    }
  }

}